const homeContent = {
  section1: {
    description: `Mettez votre musique sur les meilleures plateformes du monde Spotify, iTunes, Deezer, Anghami, Beatport, Tracksource, et bien plus encore.`,
    description2: `Gardez 100%`,
    description3: `de vos revenus de vente de la musique que vous distribuez.`,
    linkLabel: `Créez un compte gratuitement`,
  },
  section2: {
    mainTitle: {
      part1: `Des`,
      part2: `services`,
      part3: `adaptés à vos besoins`,
    },
    cards: {
      card1: {
        title: `Distribution de musique en ligne`,
        description: `Nous distribuons votre sortie à tous les principaux services de musique numériques au monde et vous préservez vos droits d’auteurs. Atteignez le monde !`,
        linkLabel: `En savoir plus`,
      },
      card2: {
        title: `Monétisation Youtube`,
        description: `Augmentez vos revenus musicaux sur YouTube, Nous proposons également l’optimisation et la gestion des chaînes pour étendre encore votre portée.`,
        linkLabel: `En savoir plus`,
      },
      card3: {
        title: `El Aalamia`,
        description: `Du pack Fennan Pro au Mastering professionnel en studio ainsi que le pressage de vinyle, nos services partenaires vous couvrent.`,
        linkLabel: `En savoir plus`,
      }
    }
  },
  section3: {
    block1: {
      title: `Maximisez votre musique avec une équipe qui vous soutient.`,
      description: `Aux côtés de notre légendaire brigade du bonheur, tirez le meilleur parti de votre musique avec nos services de distribution, plus de 250 plateformes partenaires tels que Spotify, Anghami, Deezer, Apple Music, Amazon, Beatport, Traxsource …`,
      linkLabel: `en savoir plus`,
    },
    block2: {
      title: `Monétisation musicale`,
      description: `Augmentez vos revenus musicaux sur YouTube, SoundCloud et Audiomack. Nous proposons également l’optimisation et la gestion des chaînes YouTube pour étendre encore plus votre portée.`,
      linkLabel: `en savoir plus`,
    },
  },
  section4: {
    title: {
      part1: `ATTEIGNEZ LE MONDE !`,
      part2: `Faites-vous entendre`,
    },
    description: `Aucune carte de crédit requise pour l’ouverture de votre compte.`,
    linkLabel: `Créez un compte gratuitement`,
    linkText: `Votre première sortie est GRATUITE*`,
  },
  section5: {
    mainTitle: {
      part1: `Nos artistes`,
      part2: `parlent de nous`
    },
    secondTitle: `Ce que disent les artistes de Dropzik`,
    block1: {
      title: `Mezyane`,
      description: `Je recommande Dropzik pour plusieurs raisons : La qualité de service, la disponibilité des albums dans plusieurs plateformes et la relation avec une équipe performante et passionnée par la musique. Toujours disponible pour répondre aux besoins.`,
    },
    block2: {
      title: `Kader Japonais`,
      description: `Je recommande Dropzik pour plusieurs raisons: la qualité du service, le fait que les albums sont sur les plateformes indéniment et la relation avec l’équipe Dropzik qui est passionnée par la musique et qui est toujours là pour vous.`,
    },
    block3: {
      title: `Kenza Morsli`,
      description: `Pendants notre collaboration, Dropzik était la pièce manquante du puzzle pour atteindre des fans à l’international.  Leurs compétences et leur réactivité en termes d’accompagnement étaient d’une aide très précieuse.`,
    }
  },
  section6: {
    mainTitle: {
      part1: `Dropzik`,
      part2: `est une`,
      part3: `solution tout-en-un pour les musiciens et labels indépendants.`,
    },
    items: [
      `Touchez vos fans dans le monde entier;`,
      `Gratuit, rapide et simple d’utilisation;`,
      `Notre équipe répond en français et en arabe à vos questions en 1 jour ouvré;`,
      `Vendez et streamez votre musique sur des centaines de plateformes incluant Spotify, Deezer, Apple Music, Amazon, Beatport, Qobuz et bien d’autres;`,
      `Un seul paiement par sortie, pas de frais annuels récurrents*;`,
      `Publiez vos sorties sous nos labels gratuits (ou bien créez votre propre label);`,
      `Assurez la promotion de votre sortie grâce à une sélection d’outils marketing.`,
    ],
    description: `*À l’exception de l’abonnement LABEL qui est facturé annuellement.`,
    linkLabel: `Créez un compte gratuitement`,
  },
}

export default homeContent;