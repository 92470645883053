import React from "react"
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import homeContent from "../config/contents/home";

import DropImage from '../assets/images/home/drop.svg';
import SpotifyIcon from '../assets/icons/spotify.svg';
import iTunesIcon from '../assets/icons/itunes.svg';
import DeezerIcon from '../assets/icons/deezer.svg';
import AnghamiIcon from '../assets/icons/anghami.svg';
import BeatportIcon from '../assets/icons/beatport.svg';
import TracksourceIcon from '../assets/icons/tracksource.svg';
import AlalamiaIcon from '../assets/icons/alalamia.svg';
import OnlineMusicIcon from '../assets/icons/distribution-de-music-en-ligne.svg';
import YoutubeIcon from '../assets/icons/monetesation-youtube.svg';
import TouchTheWorld from '../assets/images/home/quotes/touch-the-world.svg';
import OurArtists from '../assets/images/home/quotes/our-artists-word.svg';
import QuotesBgGradient from '../assets/images/home/quotes/quotes-bg-gradient.svg';
import LogoWhite from '../assets/images/home/quotes/logo.svg';
import GradientButton from "../components/gradient-button";
import Wave from "../components/svg/wave";
import Platforms from "../components/platforms";
import { signup, distribution, monetisationYoutube, fenanProp } from '../assets/data/links.json';

import "../assets/css/index.css";

// TODO: resize the blured image on <Image />
const IndexPage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, heroBg, cardsImages, site, incomesImages, quotesBg, artistsImages, benefitesBg } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.home;
  const { edges: cardsImagesList } = cardsImages;
  const { edges: incomesImagesList } = incomesImages;
  const { edges: artistsImagesList } = artistsImages;
  const gradientBgStyle = {
    backgroundImage: `url(${QuotesBgGradient})`,
    backgroundPosition: '0 0'
  };

  return (
    <Layout className='home__page relative overflow-hidden' size='auto' headerClassName='navbar-wrapper'>
      <SEO title={title} description={description} path={path} image={pageImage?.childImageSharp?.fluid?.src} />

      <section className="hero__block bg-darkengrey blend-mode_lighten">

        <img className='hero__drop position-absolute' src={DropImage} />
        <Image
          className='image__full-width hero__bg position-absolute'
          src={heroBg?.childImageSharp?.fluid}
        />

        <div className='hero__content-container container'>
          <div className="hero__content-row container__row row">
            <div className="col-sm-8 pl-0 d-flex justify-content-center d-md-block flex-column d-md-block">
              <h1 className="h1 title text-uppercase hero__title mt-0">
                <strong className="d-bold">Vendez, monétisez</strong>{` `}
                votre{` `}<br /><strong className="d-bold">musique</strong>{` `}
                et atteignez des<br /><strong className="d-bold">millions d'auditeurs</strong>{` `}
                dans<br /> le <strong className="d-bold">monde entier</strong>.
              </h1>
              <div className='hero__description position-relative'>
                <p className='mb-0'>{homeContent.section1.description}</p>
                <div className="hero__icons-container d-flex justify-content-between d-md-block">
                  <img src={SpotifyIcon} alt='spotify' className='d-icon' />
                  <img src={iTunesIcon} alt='itunes' className='d-icon' />
                  <img src={DeezerIcon} alt='deezer' className='d-icon' />
                  <img src={AnghamiIcon} alt='anghami' className='d-icon' />
                  <img src={BeatportIcon} alt='beatport' className='d-icon' />
                  <img src={TracksourceIcon} alt='tracksource' className='d-icon' />
                </div>
                <p className="mb-0">
                  <strong className='d-bold'>{homeContent.section1.description2}</strong>{` `}
                  <span>{homeContent.section1.description3}</span>
                </p>
              </div>
              <div className="hero__action d-flex justify-content-center">
                <GradientButton className='hero__link' to={signup} external>{homeContent.section1.linkLabel}</GradientButton>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services__block mb-4 mb-md-5 ">

        <div className="d-block position-relative">

          <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon position_top mt-5 ml-n2 filter_blur_9 opacity_2' />
          <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon position_top' />

          <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon position_bottom mb-n5 mr-n5 size_big filter_blur_9 opacity_2' />
          <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon position_bottom size_small' />

          <div className="container">

            <h2 className="container__row row d-flex">
              <div className="d-flex flex-column title">
                <div>
                  {homeContent.section2.mainTitle.part1}{` `}
                  <strong className='d-bold'>{homeContent.section2.mainTitle.part2}</strong>{` `}
                </div>
                <div>
                  {homeContent.section2.mainTitle.part3}
                </div>
              </div>
            </h2>

            <div className="container__row row d-flex flex-column flex-sm-row cards__block">

              <div className="card card__block flex_1 border-0 rounded-0 d-flex z-index_1">
                <div className="card__image-block position-relative">
                  <Image
                    width={cardsImagesList[0]?.node?.childImageSharp?.presentationWidth}
                    height={cardsImagesList[0]?.node?.childImageSharp?.preservationHeight}
                    src={cardsImagesList[0]?.node?.childImageSharp?.fluid}
                    className='card__image rounded-0 card-img-top'
                    alt={homeContent.section2.cards.card1.title} />
                  <img className='card__icon position_center' src={OnlineMusicIcon} alt={homeContent.section2.cards.card1.title} />
                </div>
                <div className="card-content card__content d-flex flex-column flex_1">
                  <h3 className="card-title mb-0 d-bold card__title d-flex justify-content-center align-items-center text-uppercase text-center">{homeContent.section2.cards.card1.title}</h3>
                  <p className='card__description flex_1'>
                    Nous distribuons votre <strong className='font-weight-light'>sortie</strong>{` `}
                  à tous <strong className='font-weight-light'>les principaux services de musique numériques au monde</strong>{` `}
                  et vous préservez vos droits d’auteurs. <strong className='font-weight-light'>Atteignez le monde</strong> !
                </p>
                  <div className="d-flex justify-content-center">
                    <GradientButton className='card__btn d-bold' to={distribution}>{homeContent.section2.cards.card1.linkLabel}</GradientButton>
                  </div>
                </div>
              </div>
              <div className="card card__block flex_1 border-0 rounded-0 d-flex z-index_1">
                <div className="card__image rounded-0-block position-relative">
                  <Image
                    width={cardsImagesList[1]?.node?.childImageSharp?.presentationWidth}
                    height={cardsImagesList[1]?.node?.childImageSharp?.preservationHeight}
                    src={cardsImagesList[1]?.node?.childImageSharp?.fluid}
                    className='card__image rounded-0 card-img-top'
                    alt={homeContent.section2.cards.card2.title} />
                  <img className='card__icon position_center' src={YoutubeIcon} alt={homeContent.section2.cards.card1.title} />
                </div>
                <div className="card-content card__content d-flex flex-column flex_1">
                  <h3 className="card-title mb-0 d-bold card__title d-flex justify-content-center align-items-center text-uppercase text-center">{homeContent.section2.cards.card2.title}</h3>
                  <p className='card__description flex_1'>
                    <strong className='font-weight-light'>Augmentez vos revenus musicaux sur YouTube</strong>,{` `}
                  nous proposons également <strong className='font-weight-light'>l’optimisation et la gestion des chaînes</strong>{` `}
                  pour <strong className='font-weight-light'>étendre encore votre portée</strong>.
                </p>
                  <div className="d-flex justify-content-center">
                    <GradientButton
                      className='card__btn d-bold'
                      to={monetisationYoutube}>
                      {homeContent.section2.cards.card2.linkLabel}
                    </GradientButton>
                  </div>
                </div>
              </div>
              <div className="card card__block flex_1 border-0 rounded-0 d-flex z-index_1">
                <div className="card__image-block position-relative">
                  <Image
                    width={cardsImagesList[2]?.node?.childImageSharp?.presentationWidth}
                    height={cardsImagesList[2]?.node?.childImageSharp?.preservationHeight}
                    src={cardsImagesList[2]?.node?.childImageSharp?.fluid}
                    className='card__image card-img-top'
                    alt={homeContent.section2.cards.card3.title} />
                  <img className='card__icon position_center' src={AlalamiaIcon} alt={homeContent.section2.cards.card1.title} />
                </div>
                <div className="card-content card__content d-flex flex-column flex_1">
                  <h3 className="card-title mb-0 d-bold card__title d-flex justify-content-center align-items-center text-uppercase text-center">{homeContent.section2.cards.card3.title}</h3>
                  <p className='card__description flex_1'>
                    <strong className='font-weight-light'>Du pack Fennan Pro</strong> au <strong className='font-weight-light'>Mastering professionnel</strong>{` `}
                en studio ainsi que <strong className='font-weight-light'>le pressage de vinyle</strong>, nos services partenaires vous couvrent.
                </p>
                  <div className="d-flex justify-content-center">
                    <GradientButton className='card__btn d-bold' to={fenanProp}>{homeContent.section2.cards.card3.linkLabel}</GradientButton>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div className="container">

          <article className="container__row row d-flex flex-column-reverse flex-sm-row mt-3 mt-md-5 pt-4">
            <div className="card__element flex_1 mr-10px z-index_1">
              <div className="d-flex flex-column h-100">
                <h3 className="sub-title d-bold mb-4 mb-md-5">{homeContent.section3.block1.title}</h3>
                <p className='text__block font-weight-light text-white mt-n2 flex_1'>
                  Aux côtés de notre légendaire brigade du bonheur, tirez le meilleur parti de votre{` `}
                  <strong className='font-weight-light'>musique</strong> avec nos services de{` `}
                  <strong className='font-weight-light'>distribution</strong>. Plus de{` `}
                  <strong className='d-bold'>250 plateformes</strong>{` `}
                  partenaires telles que <strong className='font-weight-light'>Spotify</strong>,{` `}
                  <strong className='font-weight-light'>Anghami</strong>,{` `}
                  <strong className='font-weight-light'>Deezer</strong>,{` `}
                  <strong className='font-weight-light'>Apple Music</strong>,{` `}
                  <strong className='font-weight-light'>Amazon</strong>,{` `}
                  <strong className='font-weight-light'>Beatport</strong>,{` `}
                  <strong className='font-weight-light'>Traxsource</strong> …
                </p>
                <div className="d-block">
                  <GradientButton className='btn-style d-bold' to={distribution}>{homeContent.section3.block1.linkLabel}</GradientButton>
                </div>
              </div>
            </div>
            <div className='card__element position-relative flex_1 mb-4 mb-md-0 ml-10px side__image'>
              <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon wave-style_bg wave-position_right' />
              <Image
                className='image__fluid'
                src={incomesImagesList[0]?.node?.childImageSharp?.fluid}
                height={incomesImagesList[0]?.node?.childImageSharp?.fluid?.presentationHeight}
                width={incomesImagesList[0]?.node?.childImageSharp?.fluid?.presentationWidth}
                alt={homeContent.section3.block1.title} />
            </div>
          </article>

          <article className="container__row row d-flex flex-column flex-sm-row mt-3 mt-md-5 pt-4">
            <div className="card__element position-relative flex_1 mr-10px side__image">
              <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon wave-style_bg wave-position_left' />
              <Image
                className=''
                src={incomesImagesList[1]?.node?.childImageSharp?.fluid}
                height={incomesImagesList[1]?.node?.childImageSharp?.fluid?.presentationHeight}
                width={incomesImagesList[1]?.node?.childImageSharp?.fluid?.presentationWidth}
                alt={homeContent.section3.block1.title} />
            </div>
            <div className="card__element flex_1 ml-10px">
              <div className="d-flex flex-column h-100">
                <h3 className="sub-title d-bold mb-4 mb-md-5">{homeContent.section3.block2.title}</h3>
                <p className='text__block font-weight-light text-white flex_1'>
                  {homeContent.section3.block2.description}
                </p>
                <div className="d-block">
                  <GradientButton className='btn-style d-bold' to={monetisationYoutube}>{homeContent.section3.block1.linkLabel}</GradientButton>
                </div>
              </div>
            </div>
          </article>

        </div>

      </section>

      <section className="quotes__block position-relative">


        <Image
          src={quotesBg?.childImageSharp?.fluid}
          className='position-absolute w-100'
        />

        <div className="container position-relative">

          <aside className="container__row row motivation__block">
            <div className="d-flex w-100 justify-content-between flex-column flex-md-row mb-4">
              <div className="mb-4 mb-md-0 mr-0 mr-md-4">
                <img src={TouchTheWorld} className='mt-0 mt-md-3 mb-4' />
                <h3 style={{ letterSpacing: '0.06em' }} className='mb-0 fsz-30 text-white d-bold text-uppercase'>
                  {homeContent.section4.title.part2}
                </h3>
              </div>
              <p className="motivation__text title text-pink m-0">
                Aucune <strong className='d-bold'>carte de crédit</strong> requise pour l’ouverture de votre compte
              </p>
            </div>
            <div className="d-flex w-100 justify-content-start justify-content-md-end">
              <GradientButton className='btn-style d-bold text__block' to={signup} external>{homeContent.section4.linkLabel}</GradientButton>
            </div>
          </aside>

        </div>

        <div className="quotes__list position-relative">

          <div className="quotes__bg-wrapper">
            <img
              src={QuotesBgGradient}
              className='quotes__bg position-absolute position-x-canter'
              alt="gradient color" />
            <div style={gradientBgStyle} className='quotes__bg position-absolute w-100 hero__image-bg'></div>
          </div>

          <div className="container">
            <blockquote className="quotes__block container__row row pt-3 pt-md-5 pb-3 pb-md-5  mb-0 pb-md-5 d-block position-relative">

              <Wave stopColorFrom='#F61E40' stopColorTo='#A32378' className='wave-icon wave-style_bg wave-position_left' />

              <div className="d-flex flex-column flex-md-row justify-content-between mb-3 mb-lg-5">
                <div className="d-flex flex-column">
                  <img src={OurArtists} className='quotes__svg-word mb-1' />
                  <h3 className="m-0 quotes__title d-bold text-white text-uppercase">
                    <span>
                      {homeContent.section5.mainTitle.part2}
                    </span>
                  </h3>
                </div>
                <div className='d-none d-md-flex align-items-center'>
                  <img src={LogoWhite} className='img-fluid' />
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row cards__block pt-2">

                <div className="card card__block without-hover flex_1 border-0 rounded-0 d-flex mb-4 mb-sm-0 p-0">
                  <div className="card__image-block position-relative">
                    <Image
                      src={artistsImagesList[0]?.node?.childImageSharp?.fluid}
                      height={artistsImagesList[0]?.node?.childImageSharp?.fluid?.presentationHeight}
                      width={artistsImagesList[0]?.node?.childImageSharp?.fluid?.presentationWidth}
                      className='card__image image__fluid card__image_full rounded-0 card-img-top'
                      alt={homeContent.section5.block1.title} />
                  </div>
                  <p className='card-content card__content card__description px-0 pb-0 mb-3'>{homeContent.section5.block1.description}</p>
                  <h3 className="h4 text-uppercase m-0 fsz-22 position-relative">{homeContent.section5.block1.title}</h3>
                </div>

                <div className="card card__block without-hover flex_1 border-0 rounded-0 d-flex mb-4 mb-sm-0 p-0">
                  <div className="card__image-block position-relative">
                    <Image
                      height={artistsImagesList[1]?.node?.childImageSharp?.fluid?.presentationHeight}
                      width={artistsImagesList[1]?.node?.childImageSharp?.fluid?.presentationWidth}
                      src={artistsImagesList[1]?.node?.childImageSharp?.fluid}
                      className='card__image image__fluid card__image_full rounded-0 card-img-top'
                      alt={homeContent.section5.block2.title} />
                  </div>
                  <p className='card-content card__content card__description px-0 pb-0 mb-3'>{homeContent.section5.block2.description}</p>
                  <h3 className="h4 text-uppercase m-0 fsz-22 position-relative">{homeContent.section5.block2.title}</h3>
                </div>

                <div className="card card__block without-hover flex_1 border-0 rounded-0 d-flex mb-4 mb-sm-0 p-0">
                  <div className="card__image-block position-relative">
                    <Image
                      height={artistsImagesList[2]?.node?.childImageSharp?.fluid?.presentationHeight}
                      width={artistsImagesList[2]?.node?.childImageSharp?.fluid?.presentationWidth}
                      src={artistsImagesList[2]?.node?.childImageSharp?.fluid}
                      className='card__image image__fluid card__image_full rounded-0 card-img-top'
                      alt={homeContent.section5.block3.title} />
                  </div>
                  <p className='card-content card__content card__description px-0 pb-0 mb-3'>{homeContent.section5.block3.description}</p>
                  <h3 className="h4 text-uppercase m-0 fsz-22 position-relative">{homeContent.section5.block3.title}</h3>
                </div>

              </div>

            </blockquote>
          </div>

        </div>

      </section>

      <section className="benefits__block position-relative">

        <Image
          src={benefitesBg?.childImageSharp?.fluid}
          className='position-absolute w-100 image__fluid image-full-height opacity_3 blend-mode_luminosity'
        />

        <div className="container benefits__container top-bar">
          <div className="container__row row position-relative">

            <h3 className="benefits__title-container w-50 m-0 d-flex flex-column position-relative pt-1 pt-md-4 mb-3 mb-md-5">
              <span className="title m-0 d-bold list-item__shape">
                {homeContent.section6.mainTitle.part1}{` `}
                <span className="font-weight-light">
                  {homeContent.section6.mainTitle.part2}{` `}
                </span>
              </span>
              <span className="title m-0 font-weight-light list-item__shape">
                {homeContent.section6.mainTitle.part3}
              </span>
            </h3>

            <div className="d-flex flex-column flex-md-row text-white card__content m-0 mt-0 mt-md-4 p-0 pt-0 pt-md-2">
              <ul className='flex_1 m-0 mr-0 mr-md-2 p-0'>
                {
                  homeContent.section6.items.slice(0, -3).map(item => <li className='list__item list-item__shape list-item__shape_small list-unstyled' key={item}>{item}</li>)
                }
              </ul>
              <ul className='flex_1 m-0 ml-0 ml-md-2 p-0'>
                {
                  homeContent.section6.items.slice(-3).map(item => <li className='list__item list-item__shape list-item__shape_small list-unstyled' key={item}>{item}</li>)
                }
              </ul>
            </div>

            <p className='text-white w-100 text-center mb-3 mb-md-5'>
              {homeContent.section6.description}
            </p>

            <div className="d-flex justify-content-end w-100">
              <GradientButton className='d-bold btn-style' to={signup} external>{homeContent.section6.linkLabel}</GradientButton>
            </div>

          </div>
        </div>

      </section>

      <Platforms topWave bottomWave />

    </Layout>
  );
}

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        lang
        pages {
          home {
            title
            path
            description
          }
        }
      }
    }
    heroBg: file(relativePath: { eq: "home/3d-technology-digital-wave-particle-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pageImage: file(relativePath: { eq: "dropzik-big-logo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cardsImages: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "home/services"}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
    incomesImages: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "home/incomes"}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 490) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
    quotesBg: file(relativePath: { eq: "home/quotes-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1440) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    artistsImages: allFile(
      filter: {
        extension: {
          regex: "/(jpg)|(jpeg)|(png)/"
        }, 
        relativeDirectory: {
          eq: "home/quotes/artists"
        }
      }
      sort:{
        fields: [name],
        order: ASC
      }
      ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
    benefitesBg: file(relativePath: { eq: "home/benefits-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1440) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    platformsImage: file(relativePath: { eq: "home/platforms.png" }) {
      childImageSharp {
        fluid(maxWidth:996) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
  }
`;

export default IndexPage
